$thumbsWidth: rem(340px);
$thumbsWidthLarge: rem(480px);
$autoplayTime: 6s;

.c-showcase-banner {

	@include critical {
		background: color(navy);
		color: color(white);
		overflow: hidden;

		.c-button {

			@include hover {
				border-color: color(white);
			}
		}
	}
}

.c-showcase-banner__inner {

	@include critical {

		@include mq(wide) {
			display: flex;
		}
	}
}

.c-showcase-banner__slides {

	@include critical {

		@include mq(wide) {
			width: calc(100% - #{$thumbsWidth});
		}

		@include mq(widest) {
			width: calc(100% - #{$thumbsWidthLarge});
		}

		.tns-outer,
		.tns-ovh,
		.tns-inner {
			height: 100%;
		}

		.tns-liveregion {
			display: none;
		}

		.tns-controls {
			position: absolute;
			z-index: 2;

			[data-controls] {
				@include focus();

				&:not(:focus) {
					@include hide();
				}
			}
		}
	}
}

.c-showcase-banner__slides__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background: color(white);
			opacity: 0.5;
			height: rem(5px);
			z-index: 2;
		}

		&:after {
			opacity: 1;
			background: #009DE0;
			transform: scaleX(0);
			transform-origin: 0 0;
		}

		.c-showcase-banner--stop &,
		.c-showcase-banner--basic & {

			&:before,
			&:after {
				display: none;
			}
		}

		@include mq(0, wide) {
			@include ratio(375, 265);
		}

		@include mq(wide) {
			height: 100%;
		}
	}
}

.c-showcase-banner__slides__item {

	@include critical {
		opacity: 0;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.no-js &:first-child,
		.c-showcase-banner--basic &:first-child {
			opacity: 1;
		}

		.c-showcase-banner--basic &:not(:first-child),
		.no-js &:not(:first-child) {
			display: none;
		}

		&[aria-hidden="true"] {
			display: block;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include gradient();
			z-index: 1;
		}

		&.tns-fadeOut {
			z-index: 0;
		}

		&.tns-slide-active {
			z-index: 1;
		}

		&.tns-fadeIn {
			opacity: 1;
		}
	}
}

.c-showcase-banner__slides__picture {

	@include critical {
		position: relative;
		height: 100%;
		display: block;
		overflow: hidden;
	}
}

.c-showcase-banner__slides__video {

	@include critical {
		position: absolute;
		height: 100%;
		width: 100%;
		display: block;
		overflow: hidden;
		top: 0;

		iframe[data-video="Youtube"] {
			transform: scale(1.3);

			@include mq(wide) {
				transform: scale(1.5);
			}

			@include mq(widest) {
				transform: scale(1.3);
			}
		}
	}
}

.c-showcase-banner__slides__image {

	@include critical {
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		transition: transform 6s ease-in-out;
		transform: scale(1);

		.tns-slide-active & {
			transform: scale(1.05);
		}
	}
}

.c-showcase-banner__slides__content {

	@include critical {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;

		@include mq(wide) {
			width: calc(100% + #{$thumbsWidth});
		}

		@include mq(widest) {
			width: calc(100% + #{$thumbsWidthLarge});
		}
	}
}

.c-showcase-banner__slides__content__inner {

	@include critical {
		width: 100%;
	}
}

.c-showcase-banner__slides__copy {

	@include critical {
		padding: 0 0 spacing(default) 0;

		@include mq(desktop) {
			padding: 0 0 spacing(medium) rem(65px);
			position: relative;
		}

		@include mq(wide) {
			padding: 0 35% rem(80px) rem(65px); // percentage value stops encroachment onto thumbnails
		}

		@include mq(1700px) {
			padding-right: 25%; // percentage value stops encroachment onto thumbnails
		}
	}
}

.c-showcase-banner__slides__copy__line {

	@include critical {
		transition: opacity 0.6s ease, transform 0.6s ease;
		opacity: 0;
		transform: translateX(100px);
		transition-delay: 0.25s;
		padding: 0;
		margin: 0;
		text-transform: uppercase;

		.no-js & {
			opacity: 1;
			transform: translateX(0);
		}

		&:not(:first-child) {
			margin: rem(10px) 0 0 0;
		}

		@for $i from 1 through 5 {

			&:nth-child(#{$i + 1}) {
				transition-delay: #{0.25 + ($i * 0.1)}s;
			}
		}

		.tns-fadeIn &,
		.c-showcase-banner--basic & {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.c-showcase-banner__slides__copy__line--featured {

	@include critical {

		@include mq(desktop) {
			padding: 0 0 rem(10px) 0;
		}
	}
}

.c-showcase-banner__slides__copy__line--title {

	@include critical {
		color: color(yellow);

		a {
			color: color(yellow);
			text-decoration: none;
			transition: color 0.25s ease;

			@include hover {
				color: color(white);
			}
		}
	}
}

.c-showcase-banner__slides__copy__line--meta {

	@include critical {
		font-size: rem(14px);
		line-height: rem(20px);

		@include mq(desktop) {
			font-size: rem(20px);
			line-height: rem(26px);
		}
	}
}

.c-showcase-banner__slides__copy__line--category {

	@include critical {
		font-size: rem(14px);
		line-height: rem(20px);
		@include font-bold();

		@include mq(desktop) {
			font-size: rem(28px);
			line-height: rem(34px);
		}
	}
}

.c-showcase-banner__slides__copy__line--button {

	@include critical {

		&:not(:first-child) {
			margin: rem(15px) 0 0 0;

			@include mq(desktop) {
				margin: rem(22px) 0 0 0;
			}
		}
	}
}

.c-showcase-banner__slides__copy--play-button {

	@include critical {

		@include mq(0, desktop) {
			padding-right: rem(65px);
			position: relative;

			.c-showcase-banner__slides__copy__line--button {
				position: absolute;
				right: 0;
				bottom: spacing(default);
				margin: 0;
			}
		}
	}
}

.c-showcase-banner__slides__share {

	@include critical {
		display: none;

		@include mq(desktop) {
			display: block;
			position: absolute;
			top: -6px;
			left: -12px;

			.c-showcase-banner__slides__item--featured & {
				top: rem(38px);
			}

			.c-share__item {
				transition: opacity 0.5s ease, transform 0.5s ease;
				opacity: 0;
				transform: translateY(100px);
				transition-delay: 0.25s;

				.no-js & {
					opacity: 1;
					transform: translateX(0);
				}

				@for $i from 1 through 3 {

					&:nth-child(#{$i + 1}) {
						transition-delay: #{0.25 + ($i * 0.1)}s;
					}
				}

				.tns-fadeIn &,
				.c-showcase-banner--basic & {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}
}

.c-showcase-banner--animate {

	@include critical {

		.c-showcase-banner__slides__list:after {
			transition: transform $autoplayTime ease-in-out;
			transform: scaleX(1);
		}
	}
}

.c-showcase-banner__thumbs {

	@include critical {
		display: none;

		@include mq(wide) {
			width: $thumbsWidth;
			display: block;
			padding: rem(30px) rem(40px);
			position: relative;
			z-index: 2; // lift above .c-showcase-banner__slides__content which is wider than it's container to make centering work
		}

		@include mq(widest) {
			width: $thumbsWidthLarge;
			padding: rem(50px) rem(80px);
		}
	}
}

.c-showcase-banner__thumbs__list {

	@include critical {
		margin: 0;
		padding: 0;
		list-style: none;
	}
}

.c-showcase-banner__thumbs__item {

	@include critical {

		&:not(:first-child) {
			margin: spacing(default) 0 0 0;
		}
	}
}

.c-showcase-banner__thumbs__image {

	@include critical {
		width: rem(212px);
		display: block;
	}
}

.c-showcase-banner__thumbs__meta {

	@include critical {
		font-size: rem(12px);
		line-height: rem(18px);
		text-transform: uppercase;
		@include font-medium();
		margin: rem(5px) 0 0 0;
	}
}

.c-showcase-banner__thumbs__meta__category {

	@include critical {
		@include font-black();
		color: inherit;
		text-decoration: none;
	}
}

.c-showcase-banner__thumbs__meta .c-showcase-banner__thumbs__meta__category:nth-of-type(n + 2) {
	@include critical {
		&::before {
			content: ", ";
		}
	}
}

a.c-showcase-banner__thumbs__meta__category .c-showcase-banner__thumbs__meta__category__text {

	@include defer {

		@include hover {
			text-decoration: underline;
		}
	}
}

.c-showcase-banner__thumbs__title {

	@include critical {
		font-size: rem(16px);
		line-height: rem(22px);
		@include font-bold();
		margin: rem(10px) 0 0 0;
	}
}

.c-showcase-banner__thumbs__link {

	@include critical {
		color: color(yellow);
		text-decoration: none;
		transition: color 0.25s ease;

		@include hover {
			color: color(white);
		}
	}
}


.c-showcase-banner__tag {
	@include critical {
		color: inherit;
		text-decoration: none;
	}
}


.c-showcase-banner__tag:nth-of-type(n + 2) {
	@include critical {
		&::before {
			content: ", ";
		}
	}
}

.c-showcase-banner__tag .c-showcase-banner__tag__text {

	@include defer {

		@include hover {
			text-decoration: underline;
		}
	}
}
