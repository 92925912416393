$breakpoints: (
	largePhone: 375,
	tablet: 481,
	middle: 601,
	desktop: 768,
	largeDesktop: 900,
	wide: 1100,
	wider: 1400,
	widest: 1500
);

$colors: (
	white: #fff,
	black: #100017,
	navy: #00143b,
	royal-blue: #003399,
	cyan: #00b3ff,
	yellow: #ffcc00,
	light-grey: #e4e8ec,
	light-grey-alt: #fafafb,
	light-blue: #009DE0,
	lighter-blue: #BED4EA,
	red: #df0000,
	green: #00B245,
	catskill-white: #dfe6ef,
	black-squeeze: #E4ECF5,
	bronze: #c27b41,
	silver: #C9C9C7,
	gold: #dfbb65
);

$widths: (
	content: 1530px,
	contentNarrow: 1425px,
	contentWide: 1920px,
	contentSmall: 820px,
	contentMedium: 1650px
);

$spacings: (
	tiny: 10px,
	small: 15px,
	default: 30px,
	medium: 40px,
	large: 60px,
	massive: 100px
);

$font: 'Roboto', sans-serif;

$headerHeightMobile: 122px;
$headerHeightDesktop: 84px;
