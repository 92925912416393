@mixin critical {
	@if $critical {
		@content;
	}
}

@mixin defer {
	@if $deferred {
		@content;
	}
}

@mixin hide() {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}

@mixin show() {
	position: static;
	overflow: visible;
	clip: auto;
	height: auto;
	width: auto;
	margin: 0;
	padding: 0;
	border: 0;
}

@mixin gradient() {
	background: linear-gradient(45deg, rgba(0,26,77,1) 0%,rgba(0,44,132,0.7) 53%,rgba(0,51,153,0.5) 100%);
}

@mixin appearance-none() {
	-webkit-appearance: none;
	text-transform: uppercase;
	-moz-appearance: none;
	appearance: none;
}

@function rem($a) {
	$b: $a / 16px;
	@return $b * 1rem;
}

@mixin font-default() {
	font-family: $font;
	font-weight: 400;
}

@mixin font-medium() {
	font-family: $font;
	font-weight: 500;
}

@mixin font-bold() {
	font-family: $font;
	font-weight: 700;
}

@mixin font-black() {
	font-family: $font;
	font-weight: 900;
}

@mixin font-size-default() {
	font-size: rem(14px);
	line-height: rem(24px);
}

@mixin font-size-small() {
	font-size: rem(13px);
	line-height: rem(20px);
}

@mixin font-size-large() {
	font-size: rem(20px);
	line-height: rem(31px);
}

@mixin constrain($width: width(content), $padding: (spacing(small) / 1px)) {
	max-width: ($width + ($padding * 2));
	margin: 0 auto;
	padding-left: $padding * 1px;
	padding-right: $padding * 1px;

	@if $padding != 0 {

		@include mq(tablet) {
			padding-left: spacing(large);
			padding-right: spacing(large);
			max-width: ($width + (strip-units(spacing(large)) * 2));
		}
	}
}
@mixin clearfix() {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin ratio($width, $height, $value: 'padding-top', $important: false) {

	@if $important {
		#{$value}: (($height / $width) * 100) * 1% !important;
	}
	@else {
		#{$value}: (($height / $width) * 100) * 1%;
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}
}

@mixin hover {
	.html--can-hover &:hover {
		@content;
	}
}

@mixin focus() {
	.js-focus-visible &:focus:not(.focus-visible) {
		outline: none;
	}

	.js-focus-visible &.focus-visible {
		outline: 5px solid color(green);
	}
}

@mixin mq($from: false, $to: false) {

	$width_from: $from;
	$width_to: $to;

	@if $width_from {

		@if map-has-key($breakpoints, $from) {
			$width_from: map-get($breakpoints, $from) + px;
		}
	}

	@if $width_to {

		@if map-has-key($breakpoints, $to) {
			$width_to: (map-get($breakpoints, $to) - 1) + px;
		}
	}

	@if $width_from and $width_to {

		@media screen and (min-width: $width_from) and (max-width: $width_to) {
			@content;
		}
	}
	@else if $width_from {

		@media screen and (min-width: $width_from) {
			@content;
		}
	}
	@else if $width_to {

		@media screen and (max-width: $width_to) {
			@content;
		}
	}
}

@function color($key) {
	@if map-has-key($colors, $key) {
		@return map-get($colors, $key);
	}
	@else {
		@return $key;
	}
}

@function width($key) {
	@if map-has-key($widths, $key) {
		@return map-get($widths, $key);
	}
	@else {
		@return $key;
	}
}

@function spacing($key) {
	@if map-has-key($spacings, $key) {
		@return map-get($spacings, $key);
	}
	@else {
		@return $key;
	}
}

@function size($key) {
	@if map-has-key($sizes, $key) {
		@return map-get($sizes, $key);
	}
	@else {
		@return $key;
	}
}

@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}

@function map-extend($map, $maps... /*, $deep */ ) {
	$last: nth($maps, -1);
	$deep: $last == true;
	$max: if($deep, length($maps) - 1, length($maps));

	// Loop through all maps in $maps...
	@for $i from 1 through $max {
		// Store current map
		$current: nth($maps, $i);

		// If not in deep mode, simply merge current map with map
		@if not $deep {
			$map: map-merge($map, $current);
		}
		@else {
			// If in deep mode, loop through all tuples in current map
			@each $key, $value in $current {

				// If value is a nested map and same key from map is a nested map as well
				@if type-of($value) == "map" and type-of(map-get($map, $key)) == "map" {
					// Recursive extend
					$value: map-extend(map-get($map, $key), $value, true);
				}

				// Merge current tuple with map
				$map: map-merge($map, ($key: $value));
			}
		}
	}

	@return $map;
}
